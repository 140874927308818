import React, { Fragment } from "react";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Register } from "../../services/AuthService.js";
import { onChange } from "../Functions/OnChangeHandler.jsx";

export default function AddUser({ t, refreshData, toggleCreateUser, state }) {
  const onSubmitHandler = (e) => {
    e.preventDefault();
    Register(state.data).then((res) => {
      state.setData(res.data);
      toggleCreateUser();
      refreshData();
    });
  };

  return (
    <Fragment>
      <div className="card flex justify-content-center">
        <form className="p-fluid" onSubmit={onSubmitHandler}>
          <h3>{t("createUser")}</h3>
          <div className="field">
            <label htmlFor="email">{t("email")}</label>
            <InputText
              name="email"
              type="text"
              placeholder={t("email")}
              onChange={onChange(state)}
            />
            <Divider />
          </div>
          <div className="field">
            <label htmlFor="password">{t("password")}</label>
            <InputText
              name="password"
              type="password"
              placeholder={t("password")}
              onChange={onChange(state)}
            />
            <Divider />
          </div>

          <div className="field">
            <label htmlFor="lastName">{t("lastName")}</label>
            <InputText
              name="lastName"
              type="text"
              placeholder={t("lastName")}
              onChange={onChange(state)}
            />
            <Divider />
          </div>
          <div className="field">
            <label htmlFor="firstName">{t("firstName")}</label>
            <InputText
              name="firstName"
              type="text"
              placeholder={t("firstName")}
              onChange={onChange(state)}
            />
            <Divider />
          </div>

          <Button
            label={t("add")}
            className="p-button-sm p-component p-button-raised p-button-success w-auto"
          />
        </form>
      </div>
    </Fragment>
  );
}
