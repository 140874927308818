import React, { Fragment, useState, useEffect } from "react";
import AllUsers from "../../components/Users/AllUsers.jsx";
import { Card } from "primereact/card";
import { BreadCrumb } from "primereact/breadcrumb";
import Title from "../../components/Functions/Title.jsx";
import { ProgressSpinner } from "primereact/progressspinner";
import { SearchUser } from "../../services/UserService.js";
import AddUser from "../../components/Users/AddUser.jsx";
import { Dialog } from "primereact/dialog";

export default function Order({ t }) {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [createDialogUser, setCreateDialogUser] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const [filters, setFilters] = useState({
    page: currentPage,
    size: itemsPerPage,
    accountId: undefined,
    email: undefined,
  });

  //Call to action
  const toggleCreateUser = () => {
    setCreateDialogUser(!createDialogUser);
  };
  const items = [{ label: t("users") }];
  const home = {
    icon: "pi pi-home",
    url: "/",
  };

  const refreshData = async () => {
    await SearchUser({ ...filters }).then((res) => {
      setData(res.data);
      setLoaded(true);
    });
  };

  useEffect(() => {
    refreshData();
  }, [currentPage, itemsPerPage, filters]);

  return (
    <Fragment>
      <Title title={t("listOrders")} />
      <BreadCrumb model={items} home={home} />

      <Dialog
        visible={createDialogUser}
        style={{ width: "50vw", height: "100vh" }}
        modal
        onHide={toggleCreateUser}
      >
        <AddUser
          t={t}
          setCreateDialogUser={setCreateDialogUser}
          refreshData={refreshData}
          toggleCreateUser={toggleCreateUser}
          state={{ data, setData }}
        />
      </Dialog>

      <Card>
        {loaded ? (
          <AllUsers
            t={t}
            toggleCreateUser={toggleCreateUser}
            filtersState={{ filters, setFilters }}
            data={data}
            pageState={{
              currentPage,
              itemsPerPage,
              setCurrentPage,
              setItemsPerPage,
            }}
          />
        ) : (
          <Fragment>
            <div className="flex justify-content-center">
              <ProgressSpinner />
            </div>
          </Fragment>
        )}
      </Card>
    </Fragment>
  );
}
