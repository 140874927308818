import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { Logout } from "../services/AuthService";

export const LoginContext = createContext({});

export const LoginProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(null);
  const [data, setData] = useState({});

  // l'evenement au moment de login on recupere une key et on enregistre en local
  const login = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setIsLogged(true);
    setData(user);
  };

  // l'evenement au moment de logout pour supprimer ce qu'on enregistre en local
  const logout = async () => {
    await Logout();
    localStorage.removeItem("user");
    setIsLogged(false);
    setData(null);
  };
  //Login check
  const loggedCheck = () => {
    //localStorage data user
    const userStr = localStorage.getItem("user");
    if (userStr) {
      login(JSON.parse(userStr));
    } else {
      setIsLogged(false);
    }
  };
  //Update the state
  useEffect(loggedCheck, [isLogged]);
  return (
    <LoginContext.Provider
      value={{
        login,
        logout,
        isLogged,
        data,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
