import React, { Fragment } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Create } from "../../services/AccountService";

export default function AddAccount({ setCreateDialogAccount, refreshData, state, t }) {
  const {data, setData} = state
  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setCreateDialogAccount(false);

    Create(data).then(() => {
      refreshData()
    });
  };
  return (
    <Fragment>
      <div className="card flex justify-content-center">
        <form className="p-fluid" onSubmit={onSubmitHandler}>
          <h3>{t("createAccount")}</h3>
          <div className="field">
            <label htmlFor="companyName">{t("companyName")}</label>
            <InputText
              name="companyName"
              type="text"
              placeholder={t("companyName")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="ceoLastName">{t("ceoLastName")}</label>
            <InputText
              name="ceoLastName"
              type="text"
              placeholder={t("ceoLastName")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="ceoFirstName">{t("ceoFirstName")}</label>
            <InputText
              name="ceoFirstName"
              type="text"
              placeholder={t("ceoFirstName")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="siretNumber">{t("siretNumber")}</label>
            <InputText
              name="siretNumber"
              type="text"
              placeholder={t("siretNumber")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="email">{t("email")}</label>
            <InputText
              name="email"
              type="email"
              placeholder={t("email")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="phone">{t("phone")}</label>
            <InputText
              name="phone"
              type="text"
              placeholder={t("phone")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="address">{t("address")}</label>
            <InputText
              name="address"
              type="text"
              placeholder={t("address")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="address2">{t("address2")}</label>
            <InputText
              name="address2"
              type="text"
              placeholder={t("address2")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="zipCode">{t("zipCode")}</label>
            <InputText
              name="zipCode"
              type="text"
              placeholder={t("zipCode")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="city">{t("city")}</label>
            <InputText
              name="city"
              type="text"
              placeholder={t("city")}
              onChange={onChangeHandler}
            />
          </div>
          <div className="field">
            <label htmlFor="country">{t("country")}</label>
            <InputText
              name="country"
              type="text"
              placeholder={t("country")}
              onChange={onChangeHandler}
            />
          </div>
          <Button
            label={t("add")}
            className="p-button-sm p-component p-button-raised p-button-success w-auto"
          />
        </form>
      </div>
    </Fragment>
  );
}
