import React, { Fragment, useContext, useState } from "react";
import { Button } from "primereact/button";
import { LoginContext } from "../../context/context";

export default function LoginRegister({ t }) {
  const { isLogged, logout } = useContext(LoginContext);
  const [loginDialog, setLoginDialog] = useState(false);

  const onClickLogin = () => {
    window.location.href = "/login";
    setLoginDialog(!loginDialog);
  };
  const onClickLogout = () => {
    logout();
  };

  return (
    <Fragment>
      {!isLogged ? (
        <Fragment>
          <Button
            label={t("login")}
            className="p-button-sm p-component p-button-raised p-button-success mr-2"
            onClick={onClickLogin}
          ></Button>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            label={t("logout")}
            className="p-button-sm p-component p-button-raised p-button-danger"
            onClick={onClickLogout}
          ></Button>
        </Fragment>
      )}
    </Fragment>
  );
}
