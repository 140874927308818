import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { GetOne } from "../../services/AccountService";
import DetailsAccount from "../../components/Accounts/DetailsAccount.jsx";
import AccountConnector from "../AccountConnectors/AccountConnectors.jsx";
import Title from "../../components/Functions/Title.jsx";

export default function OneAccount({ t }) {
  const { accountId } = useParams();
  const [data, setData] = useState({});
  const refreshData = async () => {
    await GetOne(accountId)
      .then((res) => {
        setData(res.data.account);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    refreshData();
  }, []);
  return (
    <Fragment>
      <Title title={accountId} />
      <DetailsAccount
        t={t}
        accountId={accountId}
        state={{ data, setData }}
        refreshData={refreshData}
      />
      <AccountConnector t={t} accountId={accountId} />
    </Fragment>
  );
}
