import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router";
import DetailsAccountConnector from "../../components/AccountConnectors/DetailsAccountConnector.jsx";
import { GetOne } from "../../services/AccountConnectorService";
import Title from "../../components/Functions/Title.jsx";

export default function OneAccountConnector({ t }) {
  const { accountId, accountConnectorsId } = useParams();
  const [data, setData] = useState({});
  const refreshData = async () => {
    await GetOne(accountId, accountConnectorsId)
      .then((res) => {
        setData(res.data.accountConnector);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    refreshData();
  }, []);
  return (
    <Fragment>
      <Title title={t("accountConnectorDetail")} />
      <DetailsAccountConnector
        state={{ data, setData }}
        t={t}
        refreshData={refreshData}
        accountId={accountId}
        accountConnectorsId={accountConnectorsId}
      />
    </Fragment>
  );
}
