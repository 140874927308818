import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Tag } from "primereact/tag";
import SliceString from "../Functions/SliceString.jsx";
import Active from "../Functions/Active.jsx";
import "primeicons/primeicons.css";

export default function AllAccountConnectors({ data, t }) {
  //----------Formatage date---------------
  const bodyCreated = (rowData) => {
    return moment(rowData.created).format("YYYY-MM-DD HH:mm:ss");
  };

  const bodyLastProcessAt = (rowData) => {
    return moment(rowData.created).format("YYYY-MM-DD HH:mm:ss");
  };

  const bodyDetails = (rowData) => {
    return (
      <Fragment>
        <Link
          to={`/accounts/${rowData.accountId}/connectors/${rowData.accountConnectorsId}`}
        >
          <Button
            icon="pi pi-external-link"
            className="p-button-sm p-component p-button-raised p-button-info"
          />
        </Link>
      </Fragment>
    );
  };

  const bodyAccountConnectorId = (rowData) => {
    return <SliceString string={rowData.accountConnectorsId} />;
  };

  const bodyActive = (rowData) => {
    return <Active result={rowData.active} />;
  };

  const bodyType = (rowData) => {
    return rowData.type === "orders" ? (
      <Tag severity="info" value={rowData.type} />
    ) : (
      <Tag severity="success" value={rowData.type} />
    );
  };

  return (
    <Fragment>
      <DataTable
        //filters={filters}
        value={data?.accountConnectorList}
        responsiveLayout="stack"
        selectionMode={true}
        //header={headerFilter}
        globalFilterFields={[
          "accountConnectorsId",
          "accountId",
          "active",
          "type",
          "subType",
          "data",
          "lastProcessAt",
          "created",
        ]}
        filterDisplay="row"
      >
        <Column
          filter
          sortable
          body={bodyAccountConnectorId}
          header={t("accountConnectorsId")}
          filterPlaceholder={t("accountConnectorsId")}
        ></Column>
        <Column
          filter
          sortable
          body={bodyActive}
          header={t("active")}
          filterPlaceholder={t("active")}
        ></Column>
        <Column
          filter
          sortable
          field="type"
          body={bodyType}
          header={t("type")}
          filterPlaceholder={t("type")}
        ></Column>
        <Column
          filter
          sortable
          field="subType"
          header={t("subType")}
          filterPlaceholder={t("subType")}
        ></Column>
        <Column
          filter
          sortable
          field="lastProcessAt"
          body={bodyLastProcessAt}
          header={t("lastProcessAt")}
          filterPlaceholder={t("lastProcessAt")}
        ></Column>
        <Column
          filter
          sortable
          filterPlaceholder={t("createdAt")}
          field="created"
          body={bodyCreated}
          header={t("createdAt")}
        />
        <Column
          body={bodyDetails}
          header="Détails"
          exportable={false}
          style={{ minWidth: "8rem" }}
        ></Column>
      </DataTable>
    </Fragment>
  );
}
