import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import SliceString from "../Functions/SliceString.jsx";
import { ScrollTop } from "primereact/scrolltop";
import Pagination from "../Functions/Pagination.jsx";
import { Filters } from "../Functions/Filters.jsx";

export default function AllUsers({
  data,
  t,
  toggleCreateUser,
  filtersState,
  pageState,
}) {
  //---------Button Info-----------
  const bodyDetails = (rowData) => {
    return (
      <Fragment>
        <Link to={`/users/${rowData.userId}`}>
          <Button
            icon="pi pi-external-link"
            className="p-button-sm p-component p-button-raised p-button-info"
          />
        </Link>
      </Fragment>
    );
  };

  const bodyUserId = (rowData) => {
    return <SliceString string={rowData.userId} />;
  };
  //----------Formatage date---------------
  const bodyCreated = (rowData) => {
    return moment(rowData.created).format("YYYY-MM-DD HH:mm:ss");
  };

  //--------------End body template----------------

  return (
    <Fragment>
      <div className="flex justify-content-between align-items-baseline">
        <h3>{t("usersList")}</h3>
        <Pagination
          filtersState={filtersState}
          data={data}
          pageState={pageState}
        />
        <Button
          label={t("add")}
          className="p-button-sm p-component p-button-raised p-button-success"
          onClick={toggleCreateUser}
        ></Button>
      </div>
      <DataTable
        value={data?.userList}
        responsiveLayout="stack"
        selectionMode={true}
        globalFilterFields={["firstName", "lastName", "email", "created"]}
        filterDisplay="row"
      >
        <Column
          filter
          field="userId"
          sortable
          body={bodyUserId}
          header={t("userId")}
          filterPlaceholder={t("userId")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"userId"}
              value={filtersState.userId}
            />
          }
        />
        <Column
          filter
          field="firstName"
          sortable
          header={t("firstName")}
          filterPlaceholder={t("firstName")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"firstName"}
              value={filtersState.firstName}
            />
          }
        />
        <Column
          filter
          field="lastName"
          sortable
          header={t("lastName")}
          filterPlaceholder={t("lastName")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"lastName"}
              value={filtersState.lastName}
            />
          }
        />
        <Column
          filter
          sortable
          field="email"
          header={t("email")}
          filterPlaceholder={t("email")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"email"}
              value={filtersState.email}
            />
          }
        />
        <Column
          filter
          sortable
          filterPlaceholder={t("createdAt")}
          field="created"
          body={bodyCreated}
          header={t("createdAt")}
          dataType="date"
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"created"}
              value={filtersState.created}
            />
          }
        />
        <Column header="Détails" body={bodyDetails} />
      </DataTable>
      <ScrollTop threshold={300} behavior={"auto"} />
      <div className="flex flex-column justify-content-center">
        <Pagination
          filtersState={filtersState}
          data={data}
          pageState={pageState}
        />
      </div>
    </Fragment>
  );
}
