import React, { Fragment, useRef } from "react";
import { Card } from "primereact/card";
// { useNavigate } from "react-router";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ToastCopy } from "../Functions/Toast.jsx";
import { onChange } from "../Functions/OnChangeHandler.jsx";
import { Put } from "../../services/OrderService.js";
import { ToastUpdateSuccess } from "../Functions/Toast.jsx";
//import Swal from "sweetalert2";
import SliceString from "../Functions/SliceString.jsx";

export default function GetOneOrder({ orderId, t, state, refreshData }) {
  const toastCopy = useRef(null);
  const toastUpdate = useRef(null);

  const items = [
    { label: t("orders"), url: "/orders" },
    { label: <SliceString string={orderId} prefix="" />, url: `/orders/${orderId}` },
  ];

  const home = {
    icon: "pi pi-home",
    url: "/",
  };
  const onClickCopyOrderId = () => {
    navigator.clipboard.writeText(state.data.orderId);
    ToastCopy(t, toastCopy);
  };

  const onClickCopyAccountId = () => {
    navigator.clipboard.writeText(state.data.accountId);
    ToastCopy(t, toastCopy);
  };

  const onClickCopyOrderClient = () => {
    navigator.clipboard.writeText(state.data.orderClient);
    ToastCopy(t, toastCopy);
  };

  const onClickCopyTrackingNumber = () => {
    navigator.clipboard.writeText(state.data.trackingNumber);
    ToastCopy(t, toastCopy);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    Put(state.data, orderId).then((res) => {
      refreshData();
      state.setData(res);
      ToastUpdateSuccess(t, toastUpdate);
    });
  };

  return (
    <Fragment>
      <BreadCrumb model={items} home={home} />
      <Card className="">
        <div className="flex justify-content-between">
          <span className="p-input-icon-left flex align-items-center">
            <h3>{t("order")}</h3>
          </span>
          {/*<span className="p-input-icon-left">
             <SplitButton
              label="Action"
              className="p-button-outlined p-button-secondary"
              model={actionItems}
            /> 
          </span> */}
        </div>
        <form
          onSubmit={handleUpdate}
          className="card p-fluid flex w-full justify-content-around"
        >
          <div className="flex w-full flex-column">
            <div className="flex w-full flex-row justify-content-around mb-3">
              <div className="w-5">
                <div className="field">
                  <h5 className="m-0">{t("accountId")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      name="accountId"
                      readOnly
                      defaultValue={state.data.accountId}
                      onChange={onChange(state)}
                      type="text"
                      placeholder={t("accountId")}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyAccountId}
                    />
                  </div>
                  <h5 className="mb-1">{t("orderId")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      name="orderId"
                      readOnly
                      defaultValue={state.data.orderId}
                      onChange={onChange(state)}
                      type="text"
                      placeholder={t("orderId")}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyOrderId}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("orderClient")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      name="orderClient"
                      readOnly
                      defaultValue={state.data.orderClient}
                      onChange={onChange}
                      type="text"
                      placeholder={t("orderClient")}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyOrderClient}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("shipperAddress")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      name="shipperAddress"
                      readOnly
                      value={`${state.data?.shipperAddress?.company} ${state.data?.shipperAddress?.street1} ${state.data?.shipperAddress?.postcode} ${state.data?.shipperAddress?.country}`}
                      onChange={onChange(state)}
                      type="text"
                      placeholder={t("shipperAddress")}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("recipientAddress")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      name="recipientAddress"
                      readOnly
                      value={`${state.data?.recipientAddress?.lastname} ${state.data?.recipientAddress?.firstname} ${state.data?.recipientAddress?.street1} ${state.data?.recipientAddress?.street2} ${state.data?.recipientAddress?.postcode} ${state.data?.recipientAddress?.country}`}
                      onChange={onChange(state)}
                      type="text"
                      placeholder={t("recipientAddress")}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("trackingNumber")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      name="trackingNumber"
                      readOnly
                      defaultValue={state.data.trackingNumber}
                      onChange={onChange(state)}
                      type="text"
                      placeholder={t("trackingNumber")}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyTrackingNumber}
                    />
                  </div>
                </div>
              </div>
              <div className="w-5">
                <div className="field">
                  <h5 className="m-0">{t("labelGeneratedAt")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      readOnly
                      name="labelGeneratedAt"
                      defaultValue={state.data.labelGeneratedAt}
                      onChange={onChange(state)}
                      type="text"
                      placeholder={t("labelGeneratedAt")}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("shippedAt")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      readOnly
                      name="shippedAt"
                      defaultValue={state.data.shippedAt}
                      onChange={onChange(state)}
                      type="text"
                      placeholder={t("shippedAt")}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("createdAt")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      readOnly
                      name="createdAt"
                      defaultValue={state.data.created}
                      onChange={onChange(state)}
                      type="text"
                      placeholder={t("createdAt")}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("updatedAt")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      readOnly
                      name="updatedAt"
                      defaultValue={state.data.updatedAt}
                      onChange={onChange(state)}
                      type="text"
                      placeholder={t("updatedAt")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-content-around mt-4">
              <Button
                label={t("update")}
                className="p-button-sm p-component p-button-raised p-button-success w-auto"
              />
            </div>
          </div>
          <Toast ref={toastCopy} position="top-right" />
          <Toast ref={toastUpdate} position="top-center" />
        </form>
      </Card>
    </Fragment>
  );
}
