import React, { Fragment, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { Register } from "../../services/AuthService";
import { Card } from "primereact";
import { Message } from "primereact/message";
import Title from "../../components/Functions/Title.jsx";

export default function Inscription({ t }) {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  let defaultInput = undefined;
  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await Register(data).then((res) => {
        setData(res);

        navigate("/login");
      });
    } catch (error) {
      if (error.response.data.message) {
        setError(error.response.data.message);
      }
    }
  };

  return (
    <Fragment>
      <Title title={t("register")} />
      <div className="flex justify-content-center">
        <Card className="mt-5 col-12 sm:col-6 lg:col-4">
          <div className="card flex justify-content-center">
            <form className="p-fluid" onSubmit={onSubmitHandler}>
              <h3>{t("register")}</h3>
              <div className="field">
                <InputText
                  defaultValue={defaultInput}
                  name="email"
                  type="email"
                  placeholder={t("email")}
                  onChange={onChangeHandler}
                />
                <Divider />
              </div>
              <div className="field">
                <InputText
                  defaultValue={defaultInput}
                  name="password"
                  type="password"
                  placeholder={t("password")}
                  onChange={onChangeHandler}
                />
                <Divider />
              </div>

              <div className="field">
                <InputText
                  defaultValue={defaultInput}
                  name="firstName"
                  type="text"
                  placeholder={t("firstName")}
                  onChange={onChangeHandler}
                />
                <Divider />
              </div>
              <div className="field">
                <InputText
                  defaultValue={defaultInput}
                  name="lastName"
                  type="text"
                  placeholder={t("lastName")}
                  onChange={onChangeHandler}
                />
                <Divider />
              </div>

              <Button
                label={t("register")}
                className="p-button-sm p-component p-button-raised p-button-success"
              />
              {error ? (
                <Message
                  severity="error"
                  text={error}
                  icon="true"
                  className="mt-3"
                />
              ) : null}
            </form>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
