import http from "./axios.js";

const Create = (data, accountId) => {
  return http.post(`/accounts/${accountId}/connectors`, data);
};

const SearchAccountConnector = (accountId, filters) => {
  return http.post(
    `/accounts/${accountId}/connectors/search`,
    {},
    {
      params: filters,
    }
  );
};

const GetOne = (accountId, accountConnectorsId) => {
  return http.get(`/accounts/${accountId}/connectors/${accountConnectorsId}`);
};

const Update = (data, accountId, accountConnectorsId) => {
  return http.put(
    `/accounts/${accountId}/connectors/${accountConnectorsId}`,
    data
  );
};

const Delete = (accountId, accountConnectorsId) => {
  return http.delete(
    `/accounts/${accountId}/connectors/${accountConnectorsId}`
  );
};

export { Create, SearchAccountConnector, GetOne, Update, Delete };
