import React, { Fragment, useRef } from "react";
import { Card } from "primereact/card";
import "primeicons/primeicons.css";
import { BreadCrumb, Button, SplitButton } from "primereact";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Delete, Update } from "../../services/UserService";
import { Toast } from "primereact";
import { ToastCopy } from "../Functions/Toast.jsx";
import { ToastUpdateSuccess } from "../Functions/Toast.jsx";
import { onChange } from "../Functions/OnChangeHandler.jsx";
import SliceString from "../Functions/SliceString.jsx";

export default function DetailsUser({ userId, t, state, refreshData }) {
  const navigate = useNavigate();
  const toastUpdate = useRef(null);
  const toastCopy = useRef(null);

  const handleDelete = () => {
    Delete(userId).then((res) => {
      state.setData(res);
      navigate("/users");
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    Update(state.data, userId).then((res) => {
      refreshData();
      ToastUpdateSuccess(t, toastUpdate);
      state.setData(res.data);
    });
  };

  const items = [
    {
      label: t("users"),
      url: "/users",
    },
    {
      label: <SliceString string={userId} prefix=""/>,
      url: `/users/${userId}`,
    },
  ];

  const home = { icon: "pi pi-home", url: "/" };

  const actionItems = [
    {
      label: t("delete"),
      icon: "pi pi-trash text-red-600",
      command: () => {
        handleDelete();
      },
    },
  ];

  const onClickCopyUserId = () => {
    navigator.clipboard.writeText(state.data?.userId);
    ToastCopy(t, toastCopy);
  };

  return (
    <Fragment>
      <BreadCrumb model={items} home={home} />
      <Card className="">
        <div className="flex justify-content-between">
          <span className="p-input-icon-left flex align-items-center">
            <h3>{t("user")}</h3>
          </span>
          <span className="p-input-icon-left">
            <SplitButton
              label={t("action")}
              className="p-button-outlined p-button-secondary"
              model={actionItems}
            />
          </span>
        </div>
        <form
          className="card p-fluid flex w-full justify-content-around"
          onSubmit={handleUpdate}
        >
          <div className="flex w-full flex-column">
            <div className="flex w-full flex-row justify-content-around">
              <div className="w-5">
                <div className="field">
                  <h5 className="mb-1">{t("userId")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      defaultValue={state.data?.userId}
                      name="userId"
                      type="text"
                      placeholder={t("userId")}
                      onChange={onChange(state)}
                      readOnly
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyUserId}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("firstName")}</h5>
                  <InputText
                    defaultValue={state.data?.firstName}
                    name="firstName"
                    type="text"
                    placeholder={t("firstname")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("lastName")}</h5>
                  <InputText
                    defaultValue={state.data?.lastName}
                    name="lastName"
                    type="text"
                    placeholder={t("lastname")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("email")}</h5>
                  <InputText
                    defaultValue={state.data?.email}
                    name="email"
                    type="email"
                    placeholder={t("email")}
                    onChange={onChange(state)}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-content-around mt-4">
              <Button
                label={t("update")}
                className="p-button-sm p-component p-button-raised p-button-success w-auto"
              />
            </div>
          </div>
          <Toast ref={toastUpdate} />
        </form>
      </Card>
    </Fragment>
  );
}
