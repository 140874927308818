//Imports
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Functions/Title.jsx";
import GetOneOrder from "../../components/Orders/GetOneOrder.jsx";
import { GetOne } from "../../services/OrderService";
//One post page
export default function OneOrder({ t }) {
  const { orderId } = useParams();
  const [data, setData] = useState({});
  const refreshData = async () => {
    await GetOne(orderId).then((res) => {
      setData(res.data.order);
    });
  };
  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Fragment>
      <Title title={t("orderDetail")} />
      <GetOneOrder
        t={t}
        orderId={orderId}
        state={{ data, setData }}
        refreshData={refreshData}
      />
    </Fragment>
  );
}
