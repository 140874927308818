import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primeflex/primeflex.css";
//import "./styles/index.css";
import "./styles/App.scss";
import React, { Fragment, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
//import Home from "./views/Home.jsx";
import Header from "./components/Layout/Header.jsx";
import Accounts from "./views/Accounts/Accounts.jsx";
import AccountConnector from "./views/AccountConnectors/AccountConnectors.jsx";
import Orders from "./views/Orders/Orders.jsx";
import OneAccount from "./views/Accounts/OneAccount.jsx";
import OneAccountConnector from "./views/AccountConnectors/OneAccountConnector.jsx";
import OneOrder from "./views/Orders/OneOrder.jsx";
import Connexion from "./views/Auth/Login.jsx";
import Inscription from "./views/Auth/Register.jsx";
import OneUser from "./views/Users/OneUser.jsx";
import Users from "./views/Users/Users.jsx";
import { useTranslation } from "react-i18next";
import ForgetPass from "./views/Auth/ForgetPassword.jsx";
import ResetPass from "./views/Auth/ResetPassword.jsx";
import { LoginContext, LoginProvider } from "./context/context.js";

export default function App() {
  const { t, i18n } = useTranslation();
  const PrivateRoute = ({ children }) => {
    const { isLogged } = useContext(LoginContext);
    return (
      <Fragment>
        {isLogged === false ? (
          <Navigate to="/login"></Navigate>
        ) : (
          <div>{children}</div>
        )}
      </Fragment>
    );
  };
  return (
    <>
      <LoginProvider>
        <Router>
          <Header i18n={i18n} t={t} />
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Accounts t={t} />
                </PrivateRoute>
                // <PrivateRoute>
                //   <Home t={t} />{" "}
                // </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users t={t} />{" "}
                </PrivateRoute>
              }
            />
            <Route
              path="/users/:userId"
              element={
                <PrivateRoute>
                  <OneUser t={t} />
                </PrivateRoute>
              }
            />
            <Route
              path="/accounts/:accountId"
              element={
                <PrivateRoute>
                  <OneAccount t={t} />
                </PrivateRoute>
              }
            />
            <Route
              path="/accounts/:accountId/connectors/:accountConnectorsId"
              element={
                <PrivateRoute>
                  <OneAccountConnector t={t} />
                </PrivateRoute>
              }
            />
            <Route
              path="/accounts/:accountId/connectors/search"
              element={
                <PrivateRoute>
                  <AccountConnector t={t} />
                </PrivateRoute>
              }
            />
            <Route
              path="/accounts"
              element={
                <PrivateRoute>
                  <Accounts t={t} />
                </PrivateRoute>
              }
            />
            <Route
              path="/orders"
              element={
                <PrivateRoute>
                  <Orders t={t} />
                </PrivateRoute>
              }
            />
            <Route
              path="/order/:orderId"
              element={
                <PrivateRoute>
                  <OneOrder t={t} />
                </PrivateRoute>
              }
            />

            <Route path="/login" element={<Connexion t={t} />} />
            <Route path="/register" element={<Inscription t={t} />} />
            <Route path="/forgetPassword" element={<ForgetPass t={t} />} />
            <Route path="/resetPassword/:token" element={<ResetPass t={t} />} />
          </Routes>
        </Router>
      </LoginProvider>
    </>
  );
}
