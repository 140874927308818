import React, { Fragment, useState } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import Swal from "sweetalert2";
import { ForgetPassword } from "../../services/AuthService";
import { useNavigate } from "react-router";
import Title from "../../components/Functions/Title.jsx";

export default function ForgetPass({ t }) {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    ForgetPassword(data)
      .then(() => {
        navigate("/register");
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "This account doesn't exist!",
          footer: "",
        });
      });
  };

  return (
    <Fragment>
      <Title title={t("resetPassword")} />
      <div className="flex justify-content-center">
        <Card className="mt-5 col-12 sm:col-6 lg:col-4">
          <div className="card flex justify-content-center">
            <form className="p-fluid" onSubmit={onSubmitHandler}>
              <h3>{t("resetPassword")}</h3>
              <div className="field">
                <InputText
                  name="email"
                  type="text"
                  placeholder={t("email")}
                  onChange={onChangeHandler}
                />
                <Divider />
              </div>

              <Button
                label={t("resetPassword")}
                className="p-button-sm p-component p-button-raised p-button-danger"
              />
            </form>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
