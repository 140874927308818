import React, { Fragment, useState, useEffect } from "react";
import AllOrders from "../../components/Orders/AllOrders.jsx";
import { Card } from "primereact";
import { BreadCrumb } from "primereact/breadcrumb";
import Title from "../../components/Functions/Title.jsx";
import { ProgressSpinner } from "primereact/progressspinner";
import { SearchOrder } from "../../services/OrderService.js";

export default function Order({ t }) {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [createDialogOrder, setCreateDialogOrder] = useState(false);

  //Call to action
  const toggleCreateOrder = () => {
    setCreateDialogOrder(!createDialogOrder);
  };

  const [filters, setFilters] = useState({
    page: currentPage,
    size: itemsPerPage,
    orderId: undefined,
    accountId: undefined,
    companyName: undefined,
    trackingNumber: undefined,
    orderClient: undefined,
  });

  const items = [{ label: t("accounts"), url:"/accounts" }, { label: t("orders") }];
  const home = {
    icon: "pi pi-home",
    url: "/",
  };

  const refreshData = async () => {
    await SearchOrder({ ...filters }).then((res) => {
      setData(res.data);
      setLoaded(true);
    });
  };
  useEffect(() => {
    refreshData();
  }, [currentPage, itemsPerPage, filters]);
  return (
    <Fragment>
      <Title title={t("listOrders")} />
      <BreadCrumb model={items} home={home} />
      <Card>
        {loaded ? (
          <AllOrders
            t={t}
            filtersState={{ filters, setFilters }}
            toggleCreateOrder={toggleCreateOrder}
            data={data}
            pageState={{
              currentPage,
              itemsPerPage,
              setCurrentPage,
              setItemsPerPage,
            }}
          />
        ) : (
          <Fragment>
            <div className="flex justify-content-center">
              <ProgressSpinner />
            </div>
          </Fragment>
        )}
      </Card>
    </Fragment>
  );
}
