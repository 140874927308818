import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import SliceString from "../Functions/SliceString.jsx";
import { ScrollTop } from "primereact/scrolltop";
import Pagination from "../Functions/Pagination.jsx";
import { Filters } from "../Functions/Filters.jsx";

export default function AllAccounts({
  data,
  t,
  toggleCreateAccount,
  filtersState,
  pageState,
}) {
  //---------body template-----------------
  const bodyDetails = (rowData) => {
    return (
      <Fragment>
        <Link to={`/accounts/${rowData.accountId}`}>
          <Button
            icon="pi pi-external-link"
            className="p-button-sm p-component p-button-raised p-button-info"
          />
        </Link>
      </Fragment>
    );
  };

  const bodyAccountId = (rowData) => {
    return <SliceString string={rowData.accountId} />;
  };
  //----------Formatage date---------------
  const bodyCreated = (rowData) => {
    return moment(rowData.created).format("YYYY-MM-DD HH:mm:ss");
  };

  //----------------End Body Template--------------------

  return (
    <Fragment>
      <div className="flex justify-content-between align-items-baseline">
        <h3>{t("accountsList")}</h3>
        <Pagination
          data={data}
          pageState={pageState}
          filtersState={filtersState}
        />
        <Button
          label={t("add")}
          className="p-button-sm p-component p-button-raised p-button-success"
          onClick={toggleCreateAccount}
        ></Button>
      </div>
      <DataTable
        value={data?.accountList}
        responsiveLayout="stack"
        selectionMode={true}
        breakpoint="1033px"
        globalFilterFields={[
          "companyName",
          "ceoLastName",
          "ceoFirstName",
          "email",
          "phone",
          "zipCode",
          "created",
        ]}
        filterDisplay="row"
      >
        <Column
          filter
          sortable
          body={bodyAccountId}
          header={t("accountId")}
          filterPlaceholder={t("accountId")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"accountId"}
              value={filtersState.accountId}
            />
          }
        ></Column>
        <Column
          filter
          sortable
          field="companyName"
          header={t("companyName")}
          filterPlaceholder={t("companyName")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"companyName"}
              value={filtersState.companyName}
            />
          }
        ></Column>
        <Column
          filter
          sortable
          field="ceoFirstName"
          header={t("ceoFirstName")}
          filterPlaceholder={t("ceoFirstName")}
        ></Column>
        <Column
          filter
          sortable
          field="zipCode"
          header={t("zipCode")}
          filterPlaceholder={t("zipCode")}
        ></Column>
        <Column
          filter
          sortable
          field="city"
          header={t("city")}
          filterPlaceholder={t("city")}
        ></Column>
        <Column
          filter
          sortable
          field="country"
          header={t("country")}
          filterPlaceholder={t("country")}
        ></Column>
        <Column
          filter
          sortable
          filterPlaceholder={t("createdAt")}
          field="created"
          body={bodyCreated}
          header={t("createdAt")}
        />
        <Column
          body={bodyDetails}
          header="Détails"
          exportable={false}
          style={{ minWidth: "8rem" }}
        ></Column>
      </DataTable>

      <ScrollTop threshold={300} behavior={"auto"} />
      <div className="flex flex-column justify-content-center">
        <Pagination
          filtersState={filtersState}
          data={data}
          pageState={pageState}
        />
      </div>
    </Fragment>
  );
}
