import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import moment from "moment/moment";
import { ScrollTop } from "primereact/scrolltop";
import SliceString from "../Functions/SliceString.jsx";
import Pagination from "../Functions/Pagination.jsx";
import { Filters } from "../Functions/Filters.jsx";

export default function AllOrders({
  data,
  pageState,
  filtersState,
  t
}) {
  //----------Body field-----------
  const bodyCompanyName = (rowData) => {
    return rowData.Account?.companyName;
  };
  //---------Button Info-----------
  const bodyDetails = (rowData) => {
    return (
      <Fragment>
        <Link to={`/order/${rowData.orderId}`}>
          <Button
            icon="pi pi-external-link"
            className="p-button-sm p-component p-button-raised p-button-info"
          />
        </Link>
      </Fragment>
    );
  };
  //---------Button PDF-----------
  const bodyPdf = (rowData) => {
    return (
      <Fragment>
        {rowData.labelGeneratedAt === null ? null : (
          <a
            href={`${process.env.REACT_APP_LADD_SHIPPING_API_URL}/orders/${rowData.orderId}/downloadLabel`}
          >
            <Button
              icon="pi pi-file-pdf"
              className="p-button-sm p-component p-button-raised p-button-success"
            />
          </a>
        )}
      </Fragment>
    );
  };

  const bodyOrderId = (rowData) => {
    return <SliceString string={rowData.orderId} />;
  };
  //----------Formatage date---------------
  const bodyCeated = (rowData) => {
    return moment(rowData.created).format("YYYY-MM-DD HH:mm:ss");
  };
  const bodyLabelGeneratedAt = (rowData) => {
    return moment(rowData.created).format("YYYY-MM-DD HH:mm:ss");
  };
  bodyLabelGeneratedAt;

  //-------------End Body Field--------------

  return (
    <Fragment>
      <div className="flex justify-content-between align-items-baseline">
        <h3>{t("listOrders")}</h3>
        <Pagination
          data={data}
          pageState={pageState}
          filtersState={filtersState}
        />
      </div>

      <DataTable
        size="small"
        resizableColumns={true}
        columnResizeMode="fit"
        selectionMode={true}
        autoLayout={true}
        value={data?.orderList}
        responsiveLayout="stack"
        //scrollable
        globalFilterFields={[
          "orderId",
          "Account.companyName",
          "orderClient",
          "trackingNumber",
          "labelGeneratedAt",
          "createdAt",
        ]}
        filterDisplay="row"
        currentPageReportTemplate={`${data?.currentPage} of ${data?.totalPages}`}
      >
        <Column
          field="orderId"
          filter
          body={bodyOrderId}
          sortable
          header={t("orderId")}
          filterPlaceholder={t("orderId")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"orderId"}
              value={filtersState.orderId}
            />
          }
        />
        <Column
          filter
          field="Account.companyName"
          body={bodyCompanyName}
          sortable
          header={t("companyName")}
          filterPlaceholder={t("companyName")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"companyName"}
              value={filtersState.companyName}
            />
          }
        />
        <Column
          sortable
          field="orderClient"
          filter
          header={t("orderClient")}
          filterPlaceholder={t("orderClient")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"orderClient"}
              value={filtersState.orderClient}
            />
          }
        />
        <Column
          filter
          sortable
          field="trackingNumber"
          header={t("trackingNumber")}
          filterPlaceholder={t("trackingNumber")}
          filterElement={
            <Filters
              filtersState={filtersState}
              t={t}
              inputName={"trackingNumber"}
              value={filtersState.trackingNumber}
            />
          }
        />
        <Column
          filter
          sortable
          body={bodyLabelGeneratedAt}
          filterPlaceholder={t("labelGeneratedAt")}
          field="labelGeneratedAt"
          header={t("labelGeneratedAt")}
          dataType="date"
        />
        <Column
          filter
          sortable
          filterPlaceholder={t("createdAt")}
          field="created"
          body={bodyCeated}
          header={t("createdAt")}
          dataType="date"
        />
        <Column
          style={{ width: "80px" }}
          sortable
          header={t("pdf")}
          body={bodyPdf}
        />
        <Column header={t("details")} body={bodyDetails} />
      </DataTable>
      <ScrollTop threshold={300} behavior={"auto"} />
      <div className="flex flex-column justify-content-center">
        <Pagination
          data={data}
          pageState={pageState}
          filtersState={filtersState}
        />
      </div>
    </Fragment>
  );
}
