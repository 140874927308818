import http from "./axios.js";

const Create = (data) => {
  return http.post("/accounts", data);
};

const SearchAccount = (filters) => {
  return http.post(
    "/accounts/search",
    {},
    {
      params: filters,
    }
  );
};

const GetOne = (accountId) => {
  return http.get(`/accounts/${accountId}`);
};

const Update = (data, accountId) => {
  return http.put(`/accounts/${accountId}`, data);
};

const Delete = (accountId) => {
  return http.delete(`/accounts/${accountId}`);
};

export { Create, GetOne, Update, SearchAccount, Delete };
