import http from "./axios.js";

const SearchUser = (filters) => {
  return http.post(
    "/users/search",
    {},
    {
      params: filters,
    }
  );
};

const GetUser = (userId) => {
  return http.get(`/users/${userId}`);
};

const Update = (data, userId) => {
  return http.put(`/users/${userId}`, data);
};

const Delete = (userId) => {
  return http.delete(`/users/${userId}`);
};

export { GetUser, Update, SearchUser, Delete };
