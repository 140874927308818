import React, { Fragment, useRef } from "react";
import { Card } from "primereact/card";
import "primeicons/primeicons.css";
import { Delete, Update } from "../../services/AccountConnectorService";
import { BreadCrumb, SplitButton } from "primereact";
import { Button } from "primereact";
import { InputSwitch } from "primereact/inputswitch";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { ToastCopy } from "../Functions/Toast.jsx";
import { ToastUpdateSuccess } from "../Functions/Toast.jsx";
import { onChange, onChangeDataField } from "../Functions/OnChangeHandler.jsx";
import SliceString from "../Functions/SliceString.jsx";

export default function DetailsAccountConnector({
  accountId,
  accountConnectorsId,
  t,
  state,
  refreshData,
}) {
  const navigate = useNavigate();
  const toastUpdate = useRef(null);
  const toastCopy = useRef(null);

  const handleDelete = () => {
    Delete(accountId, accountConnectorsId).then(() => {
      navigate(`/accounts/${accountId}`);
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    Update(state.data, accountId, accountConnectorsId).then(() => {
      ToastUpdateSuccess(t, toastUpdate);
      refreshData();
      navigate(`/accounts/${accountId}/connectors/${accountConnectorsId}`);
    });
  };

  const items = [
    {
      label: t("accounts"),
      url: "/accounts",
    },
    {
      label: <SliceString string={accountId} prefix={state.data?.Account?.companyName} />,
      url: `/accounts/${accountId}`,
    },
    {
      label: t("accountConnectors"),
      url: `/accounts/${accountId}`,
    },
    {
      label: <SliceString string={accountConnectorsId} prefix={""} />,
      url: `/accounts/${accountId}/connectors/${accountConnectorsId}`,
    },
  ];

  const home = { icon: "pi pi-home", url: "/" };

  const actionItems = [
    {
      label: t("delete"),
      icon: "pi pi-trash text-red-600",
      command: () => {
        handleDelete();
      },
    },
  ];

  const onClickCopyAccountConnectorsId = () => {
    navigator.clipboard.writeText(state.data?.accountConnectorsId);
    ToastCopy(t, toastCopy);
  };

  const onClickCopyAccountId = () => {
    navigator.clipboard.writeText(state.data?.accountId);
    ToastCopy(t, toastCopy);
  };

  const onClickCopyCoclicoNumber = () => {
    navigator.clipboard.writeText(state.data?.coclicoNumber);
    ToastCopy(t, toastCopy);
  };

  const onClickCopyConsumerKey = () => {
    navigator.clipboard.writeText(state.data?.consumerKey);
    ToastCopy(t, toastCopy);
  };

  const onClickCopyConsumerSecret = () => {
    navigator.clipboard.writeText(state.data?.consumerSecret);
    ToastCopy(t, toastCopy);
  };

  return (
    <Fragment>
      <BreadCrumb model={items} home={home} />
      <Card className="">
        <div className="flex justify-content-between">
          <span className="p-input-icon-left flex align-items-center">
            <h3>{t("accountConnector")}</h3>
          </span>
          <span className="p-input-icon-left">
            <SplitButton
              label="Action"
              className="p-button-outlined p-button-secondary"
              model={actionItems}
            />
          </span>
        </div>
        <form
          className=" card p-fluid flex w-full justify-content-around"
          onSubmit={handleUpdate}
        >
          <div className="flex w-full flex-column">
            <div className="flex w-full flex-row justify-content-around">
              <div className="w-5">
                <div className="field mb-0">
                  <h5 className="mb-1">{t("accountConnectorsId")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      defaultValue={state.data?.accountConnectorsId}
                      readOnly
                      name="accountConnectorId"
                      type="text"
                      placeholder={t("accountConnectorId")}
                      onChange={onChange(state)}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyAccountConnectorsId}
                    />
                  </div>
                </div>
                <div className="field mb-0">
                  <h5 className="mb-1">{t("accountId")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      defaultValue={state.data?.accountId}
                      readOnly
                      name="accoundId"
                      type="text"
                      placeholder={t("accoundId")}
                      onChange={onChange(state)}
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyAccountId}
                    />
                  </div>
                </div>
                <div className="field input-active">
                  <h5 className="mb-1">{t("active")}</h5>
                  <InputSwitch
                    name="active"
                    checked={state.data?.active}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field mb-0">
                  <h5 className="mb-1">{t("type")}</h5>
                  <InputText
                    defaultValue={state.data?.type}
                    readOnly
                    name="type"
                    type="text"
                    placeholder={t("type")}
                    onChange={onChange(state)}
                  />
                </div>
              </div>
              <div className="w-5">
                <div className="field mb-0">
                  <h5 className="mb-1">{t("subType")}</h5>
                  <InputText
                    defaultValue={state.data?.subType}
                    readOnly
                    name="subType"
                    type="text"
                    placeholder={t("subType")}
                    onChange={onChange(state)}
                  />
                </div>
                {state.data?.type === "shipping" ? (
                  <Fragment>
                    <div className="field mb-0">
                      <h5 className="mb-1">{t("coclicoNumber")}</h5>
                      <div className="p-inputgroup">
                        <InputText
                          defaultValue={state.data?.data?.coclicoNumber}
                          name="data.coclicoNumber"
                          type="text"
                          placeholder={t("coclicoNumber")}
                          onChange={onChangeDataField(state)}
                        />
                        <Button
                          icon="pi pi-copy"
                          type="button"
                          className="p-button-secondary"
                          onClick={onClickCopyCoclicoNumber}
                        />
                      </div>
                    </div>
                    <div className="field mb-0">
                      <h5 className="mb-1">{t("consumerKey")}</h5>
                      <div className="p-inputgroup">
                        <InputText
                          defaultValue={state.data?.data?.consumerKey}
                          name="data.consumerKey"
                          type="text"
                          placeholder={t("consumerKey")}
                          onChange={onChangeDataField(state)}
                        />
                        <Button
                          icon="pi pi-copy"
                          type="button"
                          className="p-button-secondary"
                          onClick={onClickCopyConsumerKey}
                        />
                      </div>
                    </div>
                    <div className="field mb-0">
                      <h5 className="mb-1">{t("consumerSecret")}</h5>
                      <div className="p-inputgroup">
                        <InputText
                          defaultValue={state.data?.data?.consumerSecret}
                          name="data.consumerSecret"
                          type="text"
                          placeholder={t("consumerSecret")}
                          onChange={onChangeDataField(state)}
                        />
                        <Button
                          icon="pi pi-copy"
                          type="button"
                          className="p-button-secondary"
                          onClick={onClickCopyConsumerSecret}
                        />
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <div className="field mb-0">
                    <h5 className="mb-1">{t("url")}</h5>
                    <InputText
                      defaultValue={state.data?.data?.url}
                      name="data.url"
                      type="text"
                      placeholder={t("url")}
                      onChange={onChangeDataField(state)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-content-around mt-4">
              <Button
                label={t("update")}
                className="p-button-sm p-component p-button-raised p-button-success w-auto"
              />
            </div>
          </div>
          <Toast ref={toastUpdate} position="top-center" />
          <Toast ref={toastCopy} position="top-right" />
        </form>
      </Card>
    </Fragment>
  );
}
