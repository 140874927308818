import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
export default function Languages({ i18n, t }) {
  const [lang, setLang] = useState(i18n.language);

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    setLang(e.target.value);
  };
  const itemsDropdown = [
    { label: "EN", value: "en" },
    { label: "FR", value: "fr" },
  ];
  return (
    <Dropdown
      value={lang}
      options={itemsDropdown}
      onChange={changeLanguage}
      placeholder={t("languages")}
      className="mr-2"
    />
  );
}
