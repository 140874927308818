import http from "./axios.js";

const Create = (data) => {
  return http.post("/orders", data);
};

const GetOne = (orderId) => {
  return http.get(`/orders/${orderId}`);
};

const Put = (data, orderId) => {
  return http.put(`/orders/${orderId}`, data);
};

const Delete = (orderId) => {
  return http.delete(`/orders/${orderId}`);
};

const SearchOrder = (filters) => {
  return http.post(
    "/orders/search",
    {},
    {
      params: filters,
    }
  );
};

export { Create, GetOne, Put, Delete, SearchOrder };
