import React, { Fragment, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact";
import { LoginContext } from "../../context/context.js";

export default function UserButton({ t }) {
  const menu = useRef(null);
  const { data, logout } = useContext(LoginContext);

  const user = [
    {
      label: `${data.email}`,
      items: [
        { label: t("setting"), icon: "pi pi-cog", url: "/users" },
        {
          label: t("logout"),
          icon: "pi pi-sign-out",
          url: "/login",
          command: () => logout(),
        },
      ],
    },
  ];

  return (
    <Fragment>
      <Menu model={user} popup ref={menu} />
      <Button
        icon="pi pi-user"
        onClick={(event) => menu.current.toggle(event)}
        className="p-button-rounded p-button-info"
      />
    </Fragment>
  );
}
