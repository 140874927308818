import React, { Fragment, useState } from "react";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Create } from "../../services/AccountConnectorService";
import { CascadeSelect } from "primereact/cascadeselect";
import { useNavigate } from "react-router";

export default function AddAccountConnectors({
  setCreateDialog,
  accountId,
  t,
}) {
  const navigate = useNavigate();

  const [type, setType] = useState(null);
  const [subType, setSubType] = useState(null);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setCreateDialog(false);
    /* Read more about isConfirmed, isDenied below */
    Create(
      {
        type,
        subType,
      },
      accountId
    ).then((res) => {
      navigate(
        `/accounts/${accountId}/connectors/${res.data.accountConnector.accountConnectorsId}`
      );
    });
  };
  const types = [
    {
      name: "Shipping",
      subTypes: [
        {
          name: "Lettre Max",
          type: "shipping",
          subType: "LMAX",
        },
      ],
    },
    {
      name: "Orders",
      subTypes: [
        {
          name: "Confort OSC",
          type: "orders",
          subType: "CE-OSC",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <div className="card flex justify-content-center">
        <form className="p-fluid" onSubmit={onSubmitHandler}>
          <h3>{t("createAccountConnector")}</h3>
          <div className="field">
            <CascadeSelect
              value={subType}
              options={types}
              optionLabel={"name"}
              optionGroupLabel={"name"}
              optionGroupChildren={["subTypes"]}
              style={{ minWidth: "14rem" }}
              placeholder={"Select a Type"}
              onChange={(event) => {
                setType(event.value.type);
                setSubType(event.value.subType);
              }}
            />
            <Divider />
          </div>

          <Button
            label={t("add")}
            className="p-button-sm p-component p-button-raised p-button-success w-auto"
          />
        </form>
      </div>
    </Fragment>
  );
}
