import React, { Fragment, useRef } from "react";
import { Card } from "primereact/card";
import "primeicons/primeicons.css";
import { Delete, Update } from "../../services/AccountService";
import { BreadCrumb, SplitButton } from "primereact";
import { Button } from "primereact";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { ToastCopy } from "../Functions/Toast.jsx";
import { ToastUpdateSuccess } from "../Functions/Toast.jsx";
import { onChange } from "../Functions/OnChangeHandler.jsx";
import SliceString from "../Functions/SliceString.jsx";

export default function DetailsAccount({ accountId, t, state, refreshData }) {
  const navigate = useNavigate();
  const toastUpdate = useRef(null);
  const toastCopy = useRef(null);

  const handleDelete = () => {
    Delete(accountId).then((res) => {
      state.setData(res);
      navigate("/accounts");
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    Update(state.data, accountId).then((res) => {
      refreshData();
      state.setData(res);
      ToastUpdateSuccess(t, toastUpdate);
      navigate(`/accounts/${accountId}`);
    });
  };

  const items = [
    {
      label: t("accounts"),
      url: "/accounts",
    },
    {
      label: <SliceString string={accountId} prefix={state.data?.companyName} />,
      url: `/accounts/${accountId}`,
    },
  ];

  const home = { icon: "pi pi-home", url: "/" };

  const actionItems = [
    {
      label: t("delete"),
      icon: "pi pi-trash text-red-600",
      command: () => {
        handleDelete();
      },
    },
  ];

  const onClickCopyAccountId = () => {
    navigator.clipboard.writeText(state.data.accountId);
    ToastCopy(t, toastCopy);
  };

  return (
    <Fragment>
      <BreadCrumb model={items} home={home} />
      <Card className="">
        <div className="flex justify-content-between">
          <span className="p-input-icon-left flex align-items-center">
            <h3>{t("account")}</h3>
          </span>
          <span className="p-input-icon-left">
            <SplitButton
              label="Action"
              className="p-button-outlined p-button-secondary"
              model={actionItems}
            />
          </span>
        </div>
        <form
          className="card p-fluid flex w-full justify-content-around"
          onSubmit={handleUpdate}
        >
          <div className="flex w-full flex-column">
            <div className="flex w-full flex-row justify-content-around">
              <div className="w-5">
                <div className="field">
                  <h5 className="mb-1">{t("accountId")}</h5>
                  <div className="p-inputgroup">
                    <InputText
                      defaultValue={state.data.accountId}
                      name="accountId"
                      type="text"
                      placeholder={t("accountId")}
                      readOnly
                    />
                    <Button
                      icon="pi pi-copy"
                      type="button"
                      className="p-button-secondary"
                      onClick={onClickCopyAccountId}
                    />
                  </div>
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("companyName")}</h5>
                  <InputText
                    defaultValue={state.data.companyName}
                    name="companyName"
                    type="text"
                    placeholder={t("companyName")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("ceoFirstName")}</h5>
                  <InputText
                    defaultValue={state.data.ceoFirstName}
                    name="ceoFirstName"
                    type="text"
                    placeholder={t("ceoFirstName")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("ceoLastName")}</h5>
                  <InputText
                    defaultValue={state.data.ceoLastName}
                    name="ceoLastName"
                    type="text"
                    placeholder={t("ceoLastName")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("email")}</h5>
                  <InputText
                    defaultValue={state.data.email}
                    name="email"
                    type="email"
                    placeholder={t("email")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("phone")}</h5>
                  <InputText
                    defaultValue={state.data.phone}
                    name="phone"
                    type="text"
                    placeholder={t("phone")}
                    onChange={onChange(state)}
                  />
                </div>
              </div>
              <div className="w-5">
                <div className="field">
                  <h5 className="mb-1">{t("address")}</h5>
                  <InputText
                    defaultValue={state.data.address}
                    name="address"
                    type="text"
                    placeholder={t("address")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("address2")}</h5>
                  <InputText
                    defaultValue={state.data.address2}
                    name="address2"
                    type="text"
                    placeholder={t("address2")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("zipCode")}</h5>
                  <InputText
                    defaultValue={state.data.zipCode}
                    name="zipCode"
                    type="text"
                    placeholder={t("zipCode")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("city")}</h5>
                  <InputText
                    defaultValue={state.data.city}
                    name="city"
                    type="text"
                    placeholder={t("city")}
                    onChange={onChange(state)}
                  />
                </div>
                <div className="field">
                  <h5 className="mb-1">{t("country")}</h5>
                  <InputText
                    defaultValue={state.data.country}
                    name="country"
                    type="text"
                    placeholder={t("country")}
                    onChange={onChange(state)}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-content-around mt-4">
              <Button
                label={t("update")}
                className="p-button-sm p-component p-button-raised p-button-success w-auto"
              />
            </div>
          </div>
          <Toast ref={toastCopy} position="top-center" />
          <Toast ref={toastUpdate} position="top-center" />
        </form>
      </Card>
    </Fragment>
  );
}
