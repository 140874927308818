import React, { Fragment, useState, useEffect } from "react";
import { Card } from "primereact";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AllAccountConnectors from "../../components/AccountConnectors/AllAccountConnectors.jsx";
import AddAccountConnectors from "../../components/AccountConnectors/AddAccountConnectors.jsx";
import { SearchAccountConnector } from "../../services/AccountConnectorService.js";
import { useParams } from "react-router-dom";
import Title from "../../components/Functions/Title.jsx";
import { Paginator } from "primereact";

export default function AccountConnector({ t }) {
  const { accountId } = useParams();
  const [data, setData] = useState({
    totalPages: 0,
    currentPage: 0,
    accountconnectors: { count: 0, rows: [] },
  });
  const [createDialog, setCreateDialog] = useState(false);
  //const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    //setCurrentPage(event.page + 1);
  };

  //Call to action
  const toggleCreate = () => {
    setCreateDialog(!createDialog);
  };
  useEffect(() => {
    SearchAccountConnector(accountId).then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <Fragment>
      <Title title={t("accountConnectorsList")} />
      <Dialog
        visible={createDialog}
        style={{ width: "50vw", height: "100vh" }}
        modal
        onHide={toggleCreate}
      >
        <AddAccountConnectors
          t={t}
          data={data}
          setData={setData}
          setCreateDialog={setCreateDialog}
          accountId={accountId}
        />
      </Dialog>
      <Card>
        <div className="flex justify-content-between">
          <span className="p-input-icon-left">
            <h3>{t("accountConnectorsList")}</h3>
          </span>
          <span>
            <Paginator
              totalRecords={data?.results}
              first={basicFirst}
              rows={basicRows}
              pageLinkSize={3}
              onPageChange={onBasicPageChange}
            />
          </span>
          <span className="p-input-icon-left">
            <Button
              label={t("add")}
              className="p-button-sm p-component p-button-raised p-button-success"
              onClick={toggleCreate}
            ></Button>
          </span>
        </div>
        <AllAccountConnectors t={t} data={data} />
      </Card>
    </Fragment>
  );
}
