import { InputText } from "primereact/inputtext";
import React from "react";
import { onChangeFilters } from "./OnChangeHandler.jsx";
export const Filters = ({ filtersState, inputName, value, t }) => {
  return (
    <InputText
      name={inputName}
      defaultValue={value === "" ? (value = undefined) : value}
      placeholder={t(inputName)}
      onChange={onChangeFilters(filtersState)}
    />
  );
};
