export function ToastUpdateSuccess(t, toastUpdate) {
  toastUpdate.current.show({
    severity: "success",
    detail: t("dataUpdated"),
    life: 3000,
  });
}

export function ToastCreateSuccess(t, toastCreate) {
  toastCreate.current.show({
    severity: "success",
    detail: t("dataCreated"),
    life: 3000,
  });
}

export function ToastError(t, toastError) {
  toastError.current.show({
    severity: "error",
    detail: t("error"),
    life: 3000,
  });
}

export function ToastCopy(t, toastCopy) {
  toastCopy.current.show({
    severity: "success",
    detail: t("copy"),
    life: 3000,
  });
}
