import LoginRegister from "./LoginRegister.jsx";
import React, { Fragment, useContext } from "react";
import { Menubar } from "primereact/menubar";
import { LoginContext } from "../../context/context.js";
import Languages from "./Languages.jsx";
import UserButton from "./UserButton.jsx";

export default function Header({ i18n, t }) {
  const { isLogged } = useContext(LoginContext);

  const start = (
    <a href={"/"}>
      <h1 style={{ fontSize: "1em" }}>ladd-shipping</h1>
    </a>
  );

  const items = [
    { label: t("accounts"), url: "/accounts", className: "ml-3 mr-2" },
    { label: t("orders"), url: "/orders", className: "mx-2" },
    { label: t("users"), url: "/users", className: "mx-2" },
  ];

  const endNotLogin = (
    <Fragment>
      <div className="flex align-items-center">
        <Languages i18n={i18n} t={t} />
        <LoginRegister t={t} />
      </div>
    </Fragment>
  );

  const endlogin = (
    <Fragment>
      <div className="flex align-items-center">
        <Languages i18n={i18n} t={t} />
        <UserButton t={t} />
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      {isLogged ? (
        <Fragment>
          <Menubar
            model={items}
            start={start}
            end={endlogin}
            style={{}}
          ></Menubar>
        </Fragment>
      ) : (
        <Fragment>
          <Menubar model={[]} start={start} end={endNotLogin}></Menubar>
        </Fragment>
      )}
    </Fragment>
  );
}
