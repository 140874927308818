import http from "./axios.js";

const Register = (data) => {
  return http.post("/auth/register", data);
};

const Login = (data) => {
  return http.post("/auth/login", data);
};

const ForgetPassword = (data) => {
  return http.post("/auth/forget-password", data);
};

const ResetPassword = (data, token) => {
  return http.put(`/auth/reset-password/${token}`, data);
};
const Logout = async () => {
  await http.post("/auth/logout", {});
};

export { Register, Login, ForgetPassword, ResetPassword, Logout };
