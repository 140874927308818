export { onChange, onChangeFilters, onChangeDataField };

const onChange = (state) => {
  const onChangeHandler = (e) => {
    state.setData({
      ...state.data,
      [e.target.name]: e.target.value,
    });
  };
  return onChangeHandler;
};
const onChangeFilters = (filtersState) => {
  const onChangeFilters = (e) => {
    filtersState.setFilters({
      ...filtersState.filters,
      [e.target.name]: e.target.value,
    });
  };
  return onChangeFilters;
};

const onChangeDataField = (state) => {
  const onChangeHandler = (e) => {
    const fieldName = e.target.name;
    const fieldParts = fieldName.split(".");
    if (fieldParts.length > 1) {
      const data = state.data[fieldParts[0]];
      let currentData = data;
      for (let i = 1; i < fieldParts.length; i++) {
        if (i < fieldParts.length - 1) {
          if (!currentData[fieldParts[i]]) {
            currentData[fieldParts[i]] = {};
          }
          currentData = currentData[fieldParts[i]];
        } else {
          currentData[fieldParts[i]] = e.target.value;
        }
      }
      state.setData({
        ...state.data,
        data: data,
      });
    } else {
      state.setData({
        ...state.data,
        fieldName: e.target.value,
      });
    }
  };
  return onChangeHandler;
};
