import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DetailsUser from "../../components/Users/DetailsUser.jsx";
import { GetUser } from "../../services/UserService";
import Title from "../../components/Functions/Title.jsx";

export default function OneUser({ t }) {
  const { userId } = useParams();
  const [data, setData] = useState({});

  const refreshData = async () => {
    await GetUser(userId)
      .then((res) => {
        setData(res.data.user);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);
  return (
    <Fragment>
      <Title title={t("userDetail")} />
      <DetailsUser
        t={t}
        userId={userId}
        state={{ data, setData }}
        refreshData={refreshData}
      />
    </Fragment>
  );
}
